import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { defLinks } from "../../defs/links";
import LinkA from "../widgets/link/LinkA";
import WrapperA from "../widgets/wrapper/WrapperA";

interface Props {
    id?: string;
    name?: string;
    className?: string;
    client_currency?: string;
    onView: any;
}
const Home008 = (props: Props) => {

    const localRef = useRef(null);
    const isInView = useInView(localRef);

    useEffect(() => {
        if(isInView){
            props.onView({
                name: props.name
            })
        }
    }, [isInView])

    const things: any = [
        {
            type: 'standard',
            prop_a: 'One request at a time. 14 days money back guarantee.',
            prop_b: `${props.client_currency?.toLowerCase() === 'ngn' ? 'NGN500,000' : '$1499'}/m`,
            link: '/payment/?mode=standard',
            includes: [
                {text: 'One request at a time', bolded: false},
                {text: 'Average 48 hour delivery', bolded: false},
                {text: 'Unlimited brands and users', bolded: false},
                {text: 'Brand guidelines', bolded: false},
                {text: 'Unlimited stock photos via Envato', bolded: false},
                {text: '14 day money back guarantee', bolded: false}
            ]
        },
        {
            type: 'pro',
            prop_a: 'More possibilities and double the requests. 14 days money back guarantee.',
            prop_b: `${props.client_currency?.toLowerCase() === 'ngn' ? 'NGN800,000' : '$2399'}/m`,
            link: '/payment/?mode=pro',
            includes: [
                {text: 'Content Ideas', bolded: true},
                {text: 'Two requests at a time', bolded: true},
                {text: 'Video Editing', bolded: true},
                {text: 'Average 48 hour delivery', bolded: false},
                {text: 'Unlimited brands and users', bolded: false},
                {text: 'Brand guidelines', bolded: false},
                {text: 'Unlimited stock photos via Envato', bolded: false},
                {text: '14 day money back guarantee', bolded: false}
            ]
        }
    ];
  
    return (
        <div ref={localRef} id={props.id} className={`${props.className} relative pt-[60px]`}>
            <div className="absolute left-0 top-0 w-[44%] h-[480px] set_bg" style={{backgroundImage: 'url(/images/noises/noise_007.png)'}}></div>
            <div className="bg-colPinkB px-4 md:px-20 pt-28 pb-36">
                <img data-aos="fade-right" data-aos-duration="1500" className="absolute left-0 top-[-20px] md:w-[170px] md:h-[260px] m-auto object-fill" src="/images/sketches/sketch_013.png" width={0} height={0} sizes="100vw" alt=""/>
                <img data-aos="fade-down" data-aos-duration="1500" className="absolute left-[37.5%] md:left-[50%] top-[11px] w-[106px] h-[102px] m-auto object-fill md:translate-x-[-50%]" src="/images/sketches/sketch_014.png" width={0} height={0} sizes="100vw" alt=""/>
                <img data-aos="fade-left" data-aos-duration="1500" className="absolute right-0 top-[10px] md:w-[112px] md:h-[124px] m-auto object-fill" src="/images/sketches/sketch_015.png" width={0} height={0} sizes="100vw" alt=""/>
                <div data-aos="fade-up" data-aos-duration="1500">
                    <WrapperA>
                        <div className="mb-14">
                            <div className="mb-6 text-center font-familyA font-semibold leading-tight tracking-wide text-3xl md:text-5xl">
                                <span>Memberships levels</span>
                            </div>
                            <div className="mb-10 w-[60%] m-auto text-lg md:text-2xl text-center">
                                <span>{`Choose a plan that's right for you.`}</span>
                            </div>
                            <img className="w-[370px] h-[60px] m-auto object-fill" src="/images/sketches/sketch_016.png" width={0} height={0} sizes="100vw" alt=""/>
                        </div>
                        <div className="">
                            <div className="relative md:flex">
                                <img data-aos="fade-right" data-aos-duration="1500" className="absolute left-[3.25rem] top-[-40px] w-[38px] h-[16px] m-auto object-fill" src="/images/sketches/sketch_017.png" width={0} height={0} sizes="100vw" alt=""/>
                                <span className="absolute left-[1.5rem] top-[-15px] block w-[fit-content] uppercase text-smallest px-3 py-2 bg-colOrangeA text-white rounded-[4px]">most popular</span>
                                {
                                    things.map((thing: any, a: number) => {
                                        return (
                                            <div key={a} className="md:w-[33%]">
                                                <div className="bg-white px-6 py-8 pt-20 set_bg md:w-[calc(100%-1.5px)] md:mr-[1.5px] mb-[1.5px]" style={{backgroundImage: 'url(/images/noises/noise_005.png'}}>
                                                    <span className="mb-4 block font-familyA font-semibold leading-tight tracking-wide text-2xl capitalize">{thing.type}</span>
                                                    <span className="mb-16 block text-sm h-[40px]">{thing.prop_a}</span>
                                                    <span className="mb-2 block font-familyA font-semibold leading-tight tracking-wide text-3xl">{thing.prop_b}</span>
                                                    <span className="mb-16 block text-smaller">Get discounts on customized frequency</span>
                                                    <div className="mb-6 m-auto">
                                                        <LinkA href={thing.link} className="!w-full block text-center">
                                                            Get started
                                                        </LinkA>
                                                    </div>
                                                    <Link to={defLinks.book_us} target="_blank" className="block font-black text-small w-[fit-content] m-auto border-b-[1px] border-dotted border-black">Book a call</Link>
                                                </div>
                                                <div className="bg-white px-6 py-10 pt-8 set_bg md:w-[calc(100%-1.5px)] md:mr-[1.5px] mb-6 md:mb-[1.5px] h-[360px]" style={{backgroundImage: 'url(/images/noises/noise_005.png'}}>
                                                    <span className="mb-6 block text-small font-bold">{`What's included:`}</span>
                                                    <ul className="list-inside list-disc">
                                                        {
                                                            thing.includes.map((include: any, b: number) => <li key={`${a}_${b}`} className={`mb-2 text-small ${include.bolded ? 'font-bold' : ''}`}>{include.text}</li>)
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="md:bg-white md:w-[calc(33%+12px)] px-6 py-10 pt-20 set_bg" style={{backgroundImage: 'url(/images/noises/noise_005.png'}}>
                                    <img className="mb-6 w-[200px] h-[96px] m-auto object-fill" src="/images/sketches/sketch_018.png" width={0} height={0} sizes="100vw" alt=""/>
                                    <span className="mb-4 block font-familyA font-semibold leading-tight tracking-wide text-lg">Book a call</span>
                                    <span className="mb-4 block text-sm">Learn more about how Hyper Pels works and how it can help you.</span>
                                    <Link to={defLinks.book_us} target="_blank" className="block font-black text-small">Book a call</Link>
                                    <hr className="mt-10 w-full h-[2px] bg-black"/>
                                    <img className="mb-6 w-[200px] h-[200px] m-auto object-fill" src="/images/sketches/sketch_019.png" width={0} height={0} sizes="100vw" alt=""/>
                                    <span className="mb-4 block font-familyA font-semibold leading-tight tracking-wide text-lg">Refer a friend & earn</span>
                                    <span className="mb-4 block text-sm">Earn 5% monthly recurring commissions for each referral.</span>
                                    <Link to={defLinks.referral} target="_blank" className="block font-black text-small">Join now</Link>
                                </div>
                            </div>
                            <div className="bg-black text-white py-14 px-2 text-center">
                                <span className="block w-[fit-content] m-auto mb-4 bg-colYellowA text-black text-smallest font-semibold uppercase rounded-xl px-4 py-1">add-on</span>
                                <span className="block w-[fit-content] m-auto mb-4 font-familyA font-semibold leading-tight tracking-wide text-3xl">Custom Development</span>
                                <span className="block w-[fit-content] m-auto mb-4 text-sm">Get high-end custom websites and applications. Requires a design subscription.</span>
                                <span className="block w-[fit-content] m-auto font-familyA font-semibold leading-tight tracking-wide text-2xl">{props.client_currency?.toLowerCase() === 'ngn' ? `NGN500,000` : `$999`}/m</span>
                            </div>
                        </div>
                    </WrapperA>
                </div>
            </div>
        </div>
    );
}

export default Home008;