
interface typeLocal {
    children: any;
    className?: string;
    onEvent?: any;
}

const PopUp = ({children, className = '', onEvent = () => {}}: typeLocal) => {
  
    return (
        <div className={`${className} relative top-[50%] translate-y-[-50%] mx-auto w-full md:w-[50%] bg-white rounded-xl p-8 flex flex-col items-center justify-between text-center`}>
            {children}
        </div>
    );
}

export default PopUp;