const ButtonA = ({children, disabled = false, loading = false, className = '', type = 'button', htmlFor = '', onClick = () => {}}: typeButtonA) => {
  
    className = `${className}
        text-small md:text-[100%] text-white bg-black px-12 md:px-16 py-3 md:py-5 rounded-lg font-familyA shadow-[6px_6px_0_0_rgba(0,0,0,0.2)] transitionA hover:translate-y-[-6px] hover:shadow-[8px_8px_0_0_rgba(0,0,0,0.2)]
        ${disabled ? 'opacity-[.5] cursor-not-allowed' : 'cursor-pointer'}
    `;

    const node = loading === true ?
        <svg className="animate-spin fill-white mx-auto" width="28" height="28" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
        </svg>
        :
        children;

    return (
        <>
            {
                (type === 'button') &&
                <button
                    onClick={(e) => onClick()}
                    className={`${className}`}
                    disabled={disabled}
                >
                    {node}
                </button>
            }
            {
                (type === 'label') &&
                <label
                    onClick={(e) => onClick()}
                    className={`${className}`}
                    htmlFor={htmlFor}
                >
                    {node}
                </label>
            }
        </>
    );
}

export default ButtonA;