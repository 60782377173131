import { useEffect, useRef, useState } from "react";
import ButtonA from "../widgets/button/ButtonA";

import { motion, useInView, useScroll, useTransform } from "framer-motion";
import { onAnchorGoTo } from "../..//defs/funcs";
import { defNavs } from "../../defs/navs";
import WrapperA from "../widgets/wrapper/WrapperA";

const Home001 = ({id = '', name = '', className = '', onView = () => {}}: typeHomeA) => {

    const localRef = useRef(null);
    const isInView = useInView(localRef);

    const [states, setStates] = useState<any>({
        toggleNav: false,
        navs: [],
        projects: []
    });

    useEffect(() => {
        setStates({
            ...states,
            navs: defNavs,
            projects: [
                {name: 'project_001.png'},
                {name: 'project_002.png'},
                {name: 'project_003.png'},
                {name: 'project_004.png'},
                {name: 'project_001.png'},
                {name: 'project_002.png'},
                {name: 'project_003.png'},
                {name: 'project_004.png'}
            ]
        });
    }, []);
    useEffect(() => {
        if(isInView){
            onView({
                name: name
            })
        }
    }, [isInView])

    const gearRef: any = useRef();
    const {scrollYProgress: gearProgress} = useScroll({
        target: gearRef
    });
    const rotateVal:any = useTransform(gearProgress, [0,1], ['15deg', '-15deg']);

    const projectsRef: any = useRef();
    const {scrollYProgress} = useScroll({
        target: projectsRef
    });
    const transX:any = useTransform(scrollYProgress, [0,1], ['-40%', '0%']);
  
    return (
        <div ref={localRef} id={id} className={`relative ${className} bg-colPinkA overflow-hidden px-4 md:p-0`}>
            <div className="absolute left-0 top-0 w-[44%] h-[360px] set_bg" style={{backgroundImage: 'url(/images/noises/noise_001.png)'}}></div>
            <div className="relative pt-6 pb-14">
                <div className="mb-10">
                    <div className="stickyNavBar px-4 md:p-0 md:block flex justify-between items-center">
                        <div className="w-[70px] h-[28px] md:mb-20 md:m-auto md:w-[140px] md:h-[56px]">
                            <img className="w-full h-full object-cover" src="/images/icons/icon_001.png" width={0} height={0} sizes="100vw" alt=""/>
                        </div>
                        <svg className="md:hidden block cursor-pointer" width="20" height="20" viewBox="0 0 16 16" onClick={() => setStates({...states, toggleNav: !states.toggleNav})}>
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                    </div>
                    <div className={`${className} ${!states.toggleNav ? 'relative md:static hidden' : ''} overflow-hidden md:overflow-auto`}>
                        <div className={`md:hidden bg-white p-8 fixed left-0 top-[70px] w-full z-[1] transitionA ${states.toggleNav ? 'translate-y-0' : 'translate-y-[-100%]'}`}>
                            {
                                states.navs.map((nav: any, a: number) =>
                                    <span
                                        key={a}
                                        className={`py-4 block font-familyA tracking-wide text-sm hover:text-colOrangeA transitionA cursor-pointer`}
                                        // href={`#${nav.link}`}
                                        onClick={() => {
                                            onAnchorGoTo(nav.id);
                                            setStates({
                                                ...states,
                                                toggleNav: false
                                            })
                                        }}
                                    >
                                        {nav.text}
                                    </span>
                                )
                            }
                        </div>
                    </div>
                    <div className="relative pt-32 md:p-0">
                        <img data-aos="fade-right" data-aos-duration="1500" className="absolute left-0 top-0 md:w-[178px] md:h-[294px] object-fill" src="/images/sketches/sketch_000.png" width={0} height={0} sizes="100vw" alt=""/>
                        <img data-aos="fade-left" data-aos-duration="1500" className="absolute md:right-[128px] md:top-[-15px] md:w-[56px] md:h-[56px] object-fill" src="/images/sketches/sketch_024.png" width={0} height={0} sizes="100vw" alt=""/>
                        <img data-aos="fade-left" data-aos-duration="1500" className="absolute right-0 top-0 md:w-[233px] md:-[450px] object-fill" src="/images/sketches/sketch_001.png" width={0} height={0} sizes="100vw" alt=""/>
                        <div data-aos="fade-up" data-aos-duration="1500">
                            <WrapperA>
                                <div className="mb-6 text-5xl md:text-[4.6rem] leading-tight font-semibold text-center md:w-[50%] md:m-auto font-familyA tracking-wide">
                                    <span>Unlimited Designs for a Flat Fee</span>
                                </div>
                                <div className="mb-12 text-2xl text-center font-medium">
                                    <span>Get access to unlimited design requests and revisions.</span>
                                </div>
                                <div className="mb-6 m-auto w-[fit-content]">
                                    <ButtonA onClick={() => onAnchorGoTo('_008')}>
                                        See plans
                                    </ButtonA>
                                </div>
                                <div className="flex items-center justify-center text-small">
                                    <span className="font-medium">Design you</span>
                                    <img className="mx-1 w-[18px] h-[16px] object-cover" src="/images/others/other_001.png" width={0} height={0} sizes="100vw" alt=""/>
                                    <span>or your money back</span>
                                </div>
                            </WrapperA>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="1500" className="relative pt-10 md:pt-20">
                    <div className="absolute left-0 top-0 w-[44%] h-[360px] set_bg" style={{backgroundImage: 'url(/images/noises/noise_002.png)'}}></div>
                    <motion.div
                        ref={projectsRef}
                        className="relative whitespace-nowrap flex justify-between hide_scrollbar transitionB"
                        style={{x: transX}}
                    >
                        {
                            states.projects.map((project: any, a: number) => {
                                return (
                                    <div key={a} className="mx-3 w-[200px] h-[200px] md:w-[400px] md:h-[400px] shrink-0 transitionA hover:shadow-[0_30px_40px_#a88f83] hover:scale-[1.1]">
                                        <img
                                            className="w-full h-full object-cover"
                                            src={`/images/projects/${project.name}`}
                                            width={0}
                                            height={0}
                                            sizes="100vw"
                                            alt=""
                                        />
                                    </div>
                                )
                            })
                        }
                    </motion.div>
                    <motion.div
                        className="absolute left-[8px] top-[9.5px] md:left-[96px] md:top-[19px] w-[65px] h-[60px] md:w-[130px] md:h-[120px]"
                        ref={gearRef}
                        style={{rotate: rotateVal}}
                    >
                        <img className="w-full h-full object-cover rotate-[-30deg]" src="/images/sketches/sketch_002.png" width={0} height={0} sizes="100vw" alt=""/>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}

export default Home001;