import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { defLinks } from "../../defs/links";
import LinkA from "../widgets/link/LinkA";
import WrapperA from "../widgets/wrapper/WrapperA";

const Home006 = ({id = '', name = '', className = '', onView = () => {}}: typeHomeA) => {

    const localRef = useRef(null);
    const isInView = useInView(localRef);

    useEffect(() => {
        if(isInView){
            onView({
                name: name
            })
        }
    }, [isInView])
  
    return (
        <div ref={localRef} id={id} className={`${className} relative pt-[60px]`}>
            <div className="absolute left-0 top-0 w-[44%] h-[80px] set_bg" style={{backgroundImage: 'url(/images/noises/noise_006.png)'}}></div>
            <div className="relative bg-colBlueA px-4 md:px-20 pt-10">
                <img data-aos="fade-down" data-aos-duration="1500" className="absolute left-[50%] top-[-75px] w-[15px] h-[150px] m-auto object-fill translate-x-[-50%]" src="/images/sketches/sketch_007.png" width={0} height={0} sizes="100vw" alt=""/>
                <img data-aos="fade-left" data-aos-duration="1500" className="absolute right-0 top-[-80px] md:w-[300px] md:h-[180px] m-auto object-fill" src="/images/sketches/sketch_011.png" width={0} height={0} sizes="100vw" alt=""/>
                <div data-aos="fade-up" data-aos-duration="1500" className="py-12">
                    <WrapperA>
                        <div className="mb-20 text-center">
                            <div className="my-10 font-familyA font-semibold leading-tight tracking-wide text-3xl md:text-5xl">
                                <span>Recent work</span>
                            </div>
                            <div className="mb-10 w-[60%] m-auto text-lg md:text-2xl">
                                <span>Intuitive and visually compelling designs.</span>
                            </div>
                            <div className="mb-6 m-auto w-[fit-content]">
                                <LinkA href={defLinks.recent_works} target="_blank" className={`${className} text-small md:text-[100%] text-white bg-black px-12 md:px-16 py-3 md:py-5 rounded-lg font-familyA shadow-[6px_6px_0_0_rgba(0,0,0,0.2)] transitionA hover:translate-y-[-6px] hover:shadow-[8px_8px_0_0_rgba(0,0,0,0.2)]`}>
                                    View recent works
                                </LinkA>
                            </div>
                        </div>
                        <div className="mb-12 md:flex flex-wrap justify-between md:max-w-[1250px] md:mx-auto">
                            <img data-aos="fade-right" data-aos-duration="1500" className="mx-auto mb-8 md:mb-[20px] md:ml-0 md:mr-[20px] md:w-[calc(50%-20px)] md:h-[605px] w-full h-[302.5px] object-cover" src="/images/recent_works/work_001.png" width={0} height={0} sizes="100vw" alt=""/>
                            <img data-aos="fade-left" data-aos-duration="1500" className="mx-auto mb-8 md:mb-[20px] md:ml-[20px] md:w-[calc(50%-20px)] md:h-[605px] w-full h-[302.5px] object-cover" src="/images/recent_works/work_002.png" width={0} height={0} sizes="100vw" alt=""/>
                            <img data-aos="fade-right" data-aos-duration="1500" className="mx-auto mb-8 md:mt-[20px] md:ml-0 md:mr-[20px] md:w-[calc(50%-20px)] md:h-[605px] w-full h-[302.5px] object-cover" src="/images/recent_works/work_003.png" width={0} height={0} sizes="100vw" alt=""/>
                            <img data-aos="fade-left" data-aos-duration="1500" className="mx-auto mb-8 md:mt-[20px] md:ml-[20px] md:w-[calc(50%-20px)] md:h-[605px] w-full h-[302.5px] object-cover" src="/images/recent_works/work_004.png" width={0} height={0} sizes="100vw" alt=""/>
                        </div>
                    </WrapperA>
                </div>
            </div>
        </div>
    );
}

export default Home006;