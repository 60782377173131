import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import WrapperA from "../widgets/wrapper/WrapperA";

const Home004 = ({id = '', name = '', className = '', onView = () => {}}: typeHomeA) => {

    const localRef = useRef(null);
    const isInView = useInView(localRef);

    useEffect(() => {
        if(isInView){
            onView({
                name: name
            })
        }
    }, [isInView])
  
    return (
        <div ref={localRef} id={id} className={`${className} relative pt-12 bg-colBlueA px-4 md:px-20 pb-28`}>
            <div className="absolute left-0 top-0 w-[44%] h-[440px] set_bg" style={{backgroundImage: 'url(/images/noises/noise_004.png)'}}></div>
            <img data-aos="fade-right" data-aos-duration="1500" className="absolute left-0 top-[-20px] md:w-[140px] md:h-[280px] m-auto object-fill" src="/images/sketches/sketch_005.png" width={0} height={0} sizes="100vw" alt=""/>
            <img data-aos="fade-left" data-aos-duration="1500" className="absolute right-0 top-[-120px] md:w-[180px] md:h-[340px] m-auto object-fill" src="/images/sketches/sketch_006.png" width={0} height={0} sizes="100vw" alt=""/>
            <img data-aos="fade-down" data-aos-duration="1500" className="absolute left-[50%] top-[-70px] md:w-[12px] md:h-[140px] m-auto object-fill translate-x-[-50%]" src="/images/sketches/sketch_007.png" width={0} height={0} sizes="100vw" alt=""/>
            <WrapperA className="relative">
                <div data-aos="fade-up" data-aos-duration="1500" className="py-12">
                    <div className="mb-20 text-center">
                        <div className="my-6 m-auto md:max-w-[45%] font-familyA font-semibold leading-tight tracking-wide text-3xl md:text-5xl font-familyA">
                            {`It's `}
                            <span className="font-familyC">{`"`}</span>
                            <span>where </span>
                            <span className="relative inline-block">
                                have
                                <img className="absolute left-0 bottom-[-3px] inline w-full object-cover mr-2" src="/images/vectors/vector_012.png" width={0} height={0} sizes="100vw" alt=""/>
                            </span>
                            <span> you been</span>
                            <span className="font-familyC">{`"`}</span>
                            <span> better</span>
                        </div>
                        <div className="md:w-[60%] m-auto text-lg md:text-2xl">
                            <span>Hyper Pels replaces unreliable freelancers and expensive agencies for one flat monthly fee, with designs delivered so fast that it will blow your mind.</span>
                        </div>
                    </div>
                    <div className="md:flex">
                        <div className="bg-white md:w-[calc(50%-40px)] md:mr-[40px] px-8 py-12 flex flex-col justify-center">
                            <div>
                                <img className="w-[35px] h-[30px] m-auto md:m-0 object-cover" src="/images/others/other_002.png" width={0} height={0} sizes="100vw" alt=""/>
                                <div className="my-6 font-familyA font-semibold leading-tight tracking-wide text-3xl text-center md:text-start">
                                    <span>Tej is very talented and makes great designs.</span>
                                </div>
                            </div>
                            <div className="md:flex items-center">
                                <img className="w-[40px] h-[40px] m-auto md:m-0 object-cover rounded-full md:mr-2" src="/images/avatars/avatar_001.png" width={0} height={0} sizes="100vw" alt=""/>
                                <div className="md:flex items-center">
                                    <span className="block md:inline text-center mt-2 md:mt-0">Adrian Boysel,</span>
                                    <div className="flex items-center justify-center">
                                        <img className="mr-1 md:ml-1 w-[20px] h-[20px] object-cover inline" src="/images/others/other_004.png" width={0} height={0} sizes="100vw" alt=""/>
                                        <span className="font-bold">Instagraphics</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-16 md:mt-0 md:w-[calc(50%-40px)] md:ml-[40px] text-center md:text-start">
                            <div className="mb-6">
                                <span className="block mb-4 font-familyA font-bold text-lg tracking-wide">Totally async</span>
                                <span className="block md:w-[80%]">{`Not a fan of meetings? We aren't either; we believe in cutting to the chase and focusing on results.`}</span>
                            </div>
                            <div className="mb-6">
                                <span className="block mb-4 font-familyA font-bold text-lg tracking-wide">Manage with Coda</span>
                                <span className="block md:w-[80%]">Manage your design board using Coda. View active, queued and completed tasks with ease.</span>
                            </div>
                            <div className="">
                                <span className="block mb-4 font-familyA font-bold text-lg tracking-wide">Invite team members</span>
                                <span className="block md:w-[80%]">Invite your entire team, so anyone can submit requests and track their progress.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </WrapperA>
        </div>
    );
}

export default Home004;