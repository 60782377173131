import ButtonA from "../widgets/button/ButtonA";
import { useEffect, useRef, useState } from "react";
import { useInView } from "framer-motion";
import { affils_b } from "../../defs/affils";
import { onAnchorGoTo } from "../../defs/funcs";
import WrapperA from "../widgets/wrapper/WrapperA";
import { defLinks } from "../../defs/links";
import { Link } from "react-router-dom";

const Home010 = ({id = '', name = '', className = '', onView = () => {}}: typeHomeA) => {

    const [states, setStates] = useState<any>({
        affils: [],
        things_a: [],
        things_b: []
    });

    const localRef = useRef(null);
    const isInView = useInView(localRef);

    useEffect(() => {
        if(isInView){
            onView({
                name: name
            })
        }
    }, [isInView])
    useEffect(() => {
        setStates({
            ...states,
            affils: affils_b,
            things_a: [
                {isLink: false, text: 'Latest projects', link: '_006', target: ''},
                {isLink: false, text: 'Pricing', link: '_008', target: ''},
                {isLink: true, text: 'Contact', link: 'mailto:info@hyperpels.com?subject=Hyper%20Pels%20Inquiry', target: ''}
            ],
            things_b: [
                {isLink: false, text: 'Get started', link: '_008', target: ''},
                {isLink: true, text: 'Terms & conditions', link: defLinks.terms, target: '_blank'},
                {isLink: true, text: 'Privacy policy', link: defLinks.policy, target: '_blank'}
            ]
        });
    }, []);

    const renderThings = (things: any) => {
        const node = things.map((thing: any, a: number) =>
            thing.isLink ?
            <Link
                key={a}
                to={thing.link}
                target={thing.target}
                className="mb-4 block transitionA hover:text-colOrangeA cursor-pointer">{thing.text}
            </Link>
            :
            <span
                key={a}
                onClick={() => onAnchorGoTo(thing.link)}
                className="mb-4 block transitionA hover:text-colOrangeA cursor-pointer">{thing.text}
            </span>
        )

        return node;
    }
  
    return (
        <div ref={localRef} id={id} className={`${className} relative`}>
            <div className="absolute left-0 top-0 w-[44%] h-[385px] set_bg opacity-[0.2]" style={{backgroundImage: 'url(/images/noises/noise_007.png)'}}></div>
            <div className="bg-black px-4 md:px-20 pt-28 pb-24 md:pb-32 text-white">
                <img data-aos="fade-right" data-aos-duration="1500" className="absolute left-0 bottom-0 w-[60px] h-[85px] md:w-[120px] md:h-[170px] m-auto object-fill" src="/images/sketches/sketch_022.png" width={0} height={0} sizes="100vw" alt=""/>
                <div data-aos="fade-up" data-aos-duration="1500">
                    <WrapperA>
                        <div className="mb-24 m-auto md:w-[65%]">
                            <div className="mb-10 text-center font-familyA font-semibold leading-tight tracking-wide text-3xl md:text-5xl">
                                <span className="inline md:block md:mb-4">You deserve Hyper Pels. </span>
                                <span className="inline md:block">{`It's the best choice for you.`}</span>
                            </div>
                            <div className="mb-16 text-lg md:text-2xl text-center md:w-[95%] md:mx-auto">
                                <span>Experience Hyper Pels in action, and discover how you and your team can revolutionize your design sourcing, once and for all.</span>
                            </div>
                            <div className="">
                                <ButtonA onClick={() => onAnchorGoTo('_008')} className="!w-full block text-center !py-14 border-[1px] border-white !rounded-[20px] !text-lg md:!text-3xl hover:bg-white hover:text-black hover:!shadow-[8px_8px_0_0_rgba(255,255,255,.24)]">
                                    Get started
                                </ButtonA>
                            </div>
                        </div>
                        <div className="mb-32 flex flex-wrap md:flex-nowrap justify-center md:justify-around items-center shrink-0 w-full">
                            {
                                states.affils.map((affil: any, a: number) => {
                                    return (
                                        <div key={a} className="mx-3 my-3 md:my-0 md:mx-3 w-full h-full" style={{maxWidth: affil.width, maxHeight: affil.height}}>
                                            <img
                                                className="w-full h-full object-cover"
                                                src={`/images/affils/${affil.name}`}
                                                width={0}
                                                height={0}
                                                sizes="100vw"
                                                alt="hyper"
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="md:flex justify-between">
                            <div className="flex flex-wrap items-center h-[fit-content]">
                                <Link to="#" className="mr-10">
                                    <img className="w-[120px] h-[48px] object-fill" src="/images/icons/icon_002.png" width={0} height={0} sizes="100vw" alt=""/>
                                </Link>
                                <div className="my-8 md:my-0 flex items-center font-familyC">
                                    <img className="w-[50px] h-[40px] object-fill mr-4" src="/images/sketches/sketch_023.png" width={0} height={0} sizes="100vw" alt=""/>
                                    <div className="text-small">
                                        <span className="block">Hyper Pels is proudly made</span>
                                        <span className="block">and headquartered in Lagos.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex text-sm tracking-wide">
                                <div>
                                    {renderThings(states.things_a)}
                                </div>
                                <div className="ml-16">
                                    {renderThings(states.things_b)}
                                </div>
                            </div>
                        </div>
                    </WrapperA>
                </div>
            </div>
        </div>
    );
}

export default Home010;