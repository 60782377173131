
import 'aos/dist/aos.css';
import { useEffect, useState } from "react";

import { roundUpMoneyDigit } from '../../defs/funcs/digit';

import FormA from '../widgets/form/FormA';
import PopUp from '../widgets/popup/PopUp';
import Payment001 from './Payment001';
import PaymentPopUp001 from './PaymentPopUp001';
import PaymentPopUp002 from './PaymentPopUp002';

const Payment = () => {
    const [clientCurrency, setClientCurrency] = useState<string |  undefined>(undefined);
    const [states, setStates] = useState<any>({
        popUp: {
            index: 0,
            obj: {},
            node: <></>
        },
        form2: {
            title: '',
            formArray: [],
            formCaption: '',
            formSubmitObj: {}
        },
    });
    // useEffect(() => {
    //     AOS.init();
    // }, []);

    const onEvent = ({type = '', value = {}}: typeOnEventObj) => {
        let newStates = {...states};

        if(type === 'method_1'){
            const newObj = {
                mode: value.mode,
                client_currency: clientCurrency,
                est_amounts: {
                    USD: {mon_1: roundUpMoneyDigit(value.amounts.USD.mon_1, true).to_human, mon_3: roundUpMoneyDigit(value.amounts.USD.mon_3, true).to_human},
                    GBP: {mon_1: roundUpMoneyDigit(value.amounts.GBP.mon_1).to_human, mon_3: roundUpMoneyDigit(value.amounts.GBP.mon_3).to_human},
                    EUR: {mon_1: roundUpMoneyDigit(value.amounts.EUR.mon_1).to_human, mon_3: roundUpMoneyDigit(value.amounts.EUR.mon_3).to_human},
                    NGN: {mon_1: roundUpMoneyDigit(value.amounts.NGN.mon_1, true).to_human, mon_3: roundUpMoneyDigit(value.amounts.NGN.mon_3, true).to_human}
                }
            };

            newStates.popUp = {
                ...newStates.popUp,
                index: 1,
                obj: newObj
            };
        }
        else if(type === 'pop_up'){
            const index = value.index;
            const node = value.node || states.popUp.node;
            newStates.popUp = {
                ...newStates.popUp,
                index,
                obj: {},
                node
            };
        }
        else if(type === 'reset_pop_up'){
            newStates.popUp = {
                ...newStates.popUp,
                index: 0,
                obj: {}
            };
        }

        setStates({
            ...newStates,
        });
    }

    const getFormArrayB = (email: string) => {
        return [
            {
                actedOn: false,
                type: 'text',
                name: 'full_name',
                placeholder: 'Full Name',
                value: '',
                datalist: [],
                required: true,
                pattern: {
                    regEx: /\b([A-ZÀ-ÿ][-,a-zA-Z. ']+[ ]*)+/,
                    errorMessage: 'invalid name'
                }
            },
            {
                actedOn: (email !== '' ? true : false),
                type: 'text',
                name: 'email',
                placeholder: 'Email',
                value: email,
                datalist: [],
                required: true,
                pattern: {
                    regEx: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/,
                    errorMessage: 'invalid email'
                }
            },
            {
                actedOn: false,
                type: 'file',
                name: 'payment_proof',
                placeholder: 'Proof of Payment',
                value: '',
                datalist: [],
                files: [],
                multiple: false,
                accept: '*',
                required: true,
                pattern: {
                    regEx: '',
                    errorMessage: 'select a file'
                }
            },
            {
                actedOn: false,
                type: 'textarea',
                name: 'note',
                placeholder: 'Note',
                value: '',
                datalist: []
            },
        ];
    }
    useEffect(() => {
        const titleB = 'Get Onboarded';
        const formSubmitObjB = {loading: false, text: 'Send Proof of Payment'};

        const capNode = <>
            Kindly Ensure that information provided is accurate and email is reachable. Further instructions will be provided to the email provided above. For inquiries, <span className="underline font-bold text-black">Contact Us</span>
        </>;
        const formCaptionB = {node: capNode};
        const formArrayB: typeFormObject[] = getFormArrayB('');

        setStates({
            ...states,
            form2: {
                title: titleB,
                formArray: formArrayB,
                formCaption: formCaptionB,
                formSubmitObj: formSubmitObjB
            }
        });
    }, []);
    useEffect(() => {
        fetch(`https://ipapi.co/currency/`)
        .then(resp => resp.text())
        .then((currency) => {
            setClientCurrency(currency);
        });
    }, []);
    const onFormEvent = (obj: any) => {
        const type = obj.type;
        if(type === 'submit'){
            const form = obj.form;
            console.log(form);

            const httpfile = 'https://hyperpels.com/api/onboard/';
            fetch(httpfile, {
                method: 'POST',
                body: form
            })
            .then(response => response.text())
            .then(outdata => {
                // console.log(outdata);
                const resp: any = JSON.parse(outdata);
                // console.log(outdata);
                const successful = resp.successful;
                const error = resp.error;

                let popIndx = 4;
                let node = <></>;

                if(successful){
                    popIndx = 3;
                }
                else {
                    node = <>
                        <span className="block text-[red] text-lg md:text-2xl font-semibold">Error!</span>
                        <span className="block mt-4">{error}</span>
                    </>;
                }

                onEvent({type: 'pop_up', value: {index: popIndx, node}});
            })
            .catch(error => {
                // console.log(error);
                const node = <>
                    <span className="block text-[red] text-lg md:text-2xl font-semibold">Error!</span>
                    <span className="block mt-4">fetch error</span>
                </>;
                onEvent({type: 'pop_up', value: {index: 4, node}});
            });
        }
        else if(type === 'close'){
            onEvent({type: 'reset_pop_up'});
        }
    }

    return (
        <div className="relative">
            <Payment001
                client_currency={clientCurrency}
                onEvent={onEvent}
            />
            {
                states.popUp.index > 0 &&
                <div className="fixed z-[1] left-0 top-0 w-screen h-screen p-4 md:py-12 overflow-y-auto hide_scrollbar">
                    <div className="fixed left-0 top-0 w-screen h-screen bg-black/[.24] cursor-pointer" onClick={() => onEvent({type: 'reset_pop_up'})}></div>
                    {
                        states.popUp.index === 1 &&
                        <PaymentPopUp001
                            obj={states.popUp.obj}
                            client_currency={clientCurrency}
                            onEvent={onEvent}
                        />
                    }
                    {
                        states.popUp.index === 2 &&
                        <FormA
                            title={states.form2.title}
                            formArray={states.form2.formArray}
                            caption={states.form2.formCaption}
                            submit={states.form2.formSubmitObj}
                            onEvent={onFormEvent}
                        />
                    }
                    {
                        states.popUp.index === 3 &&
                        <PaymentPopUp002
                            onEvent={onEvent}
                        />
                    }
                    {
                        states.popUp.index === 4 &&
                        <PopUp>
                            {states.popUp.node}
                        </PopUp>
                    }
                </div>
            }
        </div>
    );
}

export default Payment;