
export const onAnchorGoTo = (id: string) => {
    if(id){
        const node = document.querySelector(`#${id}`);
        if(node){
            node.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }
}

export const stickyNavBar = () => {
    // When the user scrolls the page, execute myFunction
    window.onscroll = function() {stick()};

    // Get the navbar
    const navbar: any = document.querySelector('.stickyNavBar');

    // Get the offset position of the navbar
    const sticky = navbar.offsetTop;

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const stick = () => {
        if (window.pageYOffset >= sticky) {
            navbar.classList.add('stick')
        } else {
            navbar.classList.remove('stick');
        }
    }
}