import { Link } from "react-router-dom";


const LinkA = ({href = '', target = '', children, className = '', onClick = () => {}}: typeLinkA) => {

    className += ' text-small md:text-[100%] text-white bg-black px-12 md:px-16 py-3 md:py-5 rounded-lg font-familyA shadow-[6px_6px_0_0_rgba(0,0,0,0.2)] transitionA hover:translate-y-[-6px] hover:shadow-[8px_8px_0_0_rgba(0,0,0,0.2)]';
  
    return (
            target !== '' ?
            <Link to={href} target={target} onClick={(e) => onClick()} className={`${className}`}>
                {children}
            </Link>
            :
            <Link to={href} onClick={(e) => onClick()} className={`${className}`}>
                {children}
            </Link>
    );
}

export default LinkA;