import { useEffect, useMemo, useState } from "react";

import { Link, useSearchParams } from "react-router-dom";
import ButtonA from "../widgets/button/ButtonA";
import WrapperA from "../widgets/wrapper/WrapperA";

interface Props {
    id?: string;
    className?: string;
    client_currency?: string;
    onEvent?: (e?: any) => void;
}

const Payment_001 = (props: Props) => {

    const [searchParams] = useSearchParams();
    const mode = useMemo(
        () => searchParams.get('mode')
        , [searchParams]
    )

    const [states, setStates] = useState<any>({
        mode,
        things: {
            a: []
        }
    });
    const [amountStates, setAmountStates] = useState<any>({
        amounts: {}
    });

    useEffect(() => {
        const a: any = [
            {
                top_img: {
                    name: 'brand_001.png',
                    width: '58px',
                },
                text: 'Make  payment in USD, GBP, and EUR via various methods such as wire transfers, ACH transfers, bank transfer and more.',
                other_imgs: {
                    up_dir: 'flags',
                    names: [
                        'flag_001.png', 'flag_002.png', 'flag_003.png',
                        ...(
                            props.client_currency?.toLowerCase() === 'ngn' ?
                            ['flag_004.png'] :
                            []
                        ),
                    ]
                },
                button_props: {disabled: false},
                coming_soon: false,
                margin_bottom: true
            },
            {
                top_img: {
                    name: 'brand_002.png',
                    width: '308.5px'
                },
                text: 'Make payment via PayPal or using your preferred card, including Visa, MasterCard, Verve, Discover, and American Express.',
                other_imgs: {
                    up_dir: 'brands',
                    names: ['brand_003.png', 'brand_004.png', 'brand_005.png', 'brand_006.png', 'brand_007.png', 'brand_008.png']
                },
                button_props: {disabled: true},
                coming_soon: true,
                margin_bottom: false
            }
        ];

        setStates({
            ...states,
            things: {
                ...states.things,
                a
            }
        });
    }, [props.client_currency]);

    useEffect(() => {
        let amounts: any = {
            USD: {mon_1: 1499, mon_3: 3899},
            GBP: {mon_1: '', mon_3: ''},
            EUR: {mon_1: '', mon_3: ''},
            NGN: {mon_1: 500000, mon_3: 1200000},
        };

        if(mode === 'pro'){
            amounts.USD = {mon_1: 2399, mon_3: 5999};
            amounts.NGN = {mon_1: 800000, mon_3: 2200000};
        }

        fetch(`https://api.frankfurter.app/latest?amount=1&from=USD`)
        .then(resp => resp.json())
        .then((data) => {
            // console.log(data);
            amounts.GBP = {mon_1: (amounts.USD.mon_1 * data.rates.GBP), mon_3: (amounts.USD.mon_3 * data.rates.GBP)};
            amounts.EUR = {mon_1: (amounts.USD.mon_1 * data.rates.EUR), mon_3: (amounts.USD.mon_3 * data.rates.EUR)};

            setAmountStates({
                ...amountStates,
                amounts
            });
        })
        .catch(error => {
            console.log(error);
            const data = {
                rates: {
                    GBP: 0.78938,
                    EUR: 0.92013
                }
            };

            amounts.GBP = {mon_1: (amounts.USD.mon_1 * data.rates.GBP), mon_3: (amounts.USD.mon_3 * data.rates.GBP)};
            amounts.EUR = {mon_1: (amounts.USD.mon_1 * data.rates.EUR), mon_3: (amounts.USD.mon_3 * data.rates.EUR)};

            setAmountStates({
                ...amountStates,
                amounts
            });
        });
    }, [states.mode]);
    
    const onProceed = (type: string) => {
        const value = {
            mode: states.mode,
            amounts: amountStates.amounts,
        };
        const eventObj: typeOnEventObj = {type, value};
        props.onEvent && props.onEvent(eventObj);
    }
  
    return (
        <div id={props.id} className={`relative ${props.className} bg-colPinkA overflow-hidden`}>
            <div className="absolute left-0 top-0 w-[44%] h-[360px] set_bg" style={{backgroundImage: 'url(/images/noises/noise_001.png)'}}></div>
            <div className="relative px-4 md:px-20 pt-6 pb-12 md:pb-24">
                <WrapperA>
                    <div className="mb-12 md:mb-24">
                        <div className="px-4 md:p-0">
                            <Link to="/" className="block w-[70px] h-[28px] mb-10 md:mb-20 mx-auto md:w-[140px] md:h-[56px]">
                                <img className="w-full h-full object-cover" src="/images/icons/icon_001.png" width={0} height={0} sizes="100vw" alt=""/>
                            </Link>
                            <div className="mb-6 text-2xl md:text-5xl font-semibold text-center font-familyA tracking-wide">
                                <span className="block">Clicks away from</span>
                                <span className="block mt-6">Design Greatness</span>
                            </div>
                            <div className="text-center md:text-xl">
                                <span>Make payment via method most suitable to you</span>
                            </div>
                        </div>
                    </div>
                    <div className="md:flex justify-center text-center">
                        {
                            states.things.a.map((thing: any, a: number) => {
                                return (
                                    <div key={a} className={`relative bg-white rounded-xl w-full md:max-w-[calc(50%-80px)] px-16 py-8 md:mx-[40px] ${thing.margin_bottom ? 'mb-24' : ''}  md:mb-0`}>
                                        <img className="mx-auto h-[49px] object-contain" src={`/images/brands/${thing.top_img.name}`} width={0} height={0} sizes="100vw" alt="" style={{width: thing.top_img.width}} />
                                        <span className="block my-8 leading-loose">{thing.text}</span>
                                        <div className="mb-8 flex items-center justify-center">
                                            {
                                                thing.other_imgs.names.map((name: string, b: number) => {
                                                    return <img key={b} className="w-[32px] h-[32px] object-contain mx-2" src={`/images/${thing.other_imgs.up_dir}/${name}`} width={0} height={0} sizes="100vw" alt=""/>
                                                })
                                            }
                                        </div>
                                        <ButtonA disabled={thing.button_props.disabled} className="!px-14 !py-4" onClick={() => onProceed('method_1')}>
                                            Proceed
                                        </ButtonA>
                                        {
                                            thing.coming_soon === true &&
                                            <img className="absolute right-[-40px] top-[-40px] w-[120px] object-contain" src="/images/sketches/sketch_025.png" width={0} height={0} sizes="100vw" alt="" />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </WrapperA>
            </div>
            <img className="w-full" src="/images/sketches/sketch_026.png" width={0} height={0} sizes="100vw" alt="" />
        </div>
    );
}

export default Payment_001;