
interface typeWrapper {
    className?: string;
    children: any;
}

const WrapperA = ({className = '', children}: typeWrapper) => {

    return (
        <div className={`${className} m-auto max-w-[1440px]`}>
            {children}
        </div>
    );
}

export default WrapperA;