import { useState } from "react";
import { receipient_details } from "../../placeholder/json/payment";

interface typeLocal {
    className?: string;
    client_currency?: string,
    obj: {
        mode: string;
        est_amounts: {
            USD: string,
            GBP: string,
            EUR: string,
            NGN: string,
        }
    } | any;
    onEvent?: any;
}

const PaymentPopUp001 = (props: typeLocal) => {
    const currencies = ['USD', 'GBP', 'EUR'];
    if(props.client_currency?.toLowerCase() === 'ngn'){
        currencies.push('NGN');
    }

    let inCurrency = props.obj.client_currency;
    if(!receipient_details.hasOwnProperty(inCurrency)){
        inCurrency = 'USD';
    }
    
    const [states, setStates] = useState({
        currency: inCurrency,
        copied: false,
        toggleSwitch: false
    });

    let currency = states.currency;

    const mode = props.obj.mode;
    const logoName = receipient_details[currency].logo_name;
    const name = receipient_details[currency].name;
    const symbol = receipient_details[currency].symbol;
    const priceMon1 = symbol + props.obj.est_amounts[currency].mon_1;
    const priceMon3 = symbol + props.obj.est_amounts[currency].mon_3;
    const holder = receipient_details[currency].holder;

    const copyTextToClipboard = (type: string, text: string) => {
        let rawText = '';

        if(type === 'all'){
            Object.entries(holder).map(([key, value]: any, a) => {
                rawText += 
`${key}
${value}

`;
            })
        }
        else {
            rawText = text;
        }

        if(navigator.clipboard){
            navigator.clipboard.writeText(rawText);
            setStates({
                ...states,
                copied: true
            })
            
            setTimeout(() => {
                setStates({
                    ...states,
                    copied: false
                })
            }, 1000)
        }
    }
  
    return (
        <>
            {
                // obj.client_currency.toLowerCase() !== 'ngn' ?
                <>
                    <div className={`${props.className} relative mx-auto w-full md:w-[50%] bg-white rounded-xl p-8`}>
                        <div className="mb-8 relative flex items-center justify-between">
                            <div className="flex items-center">
                                <img className="w-[50px] h-[50px] object-cover rounded-full" src={`/images/flags/${logoName}`} width={0} height={0} sizes="100vw" alt=""/>
                                <div className="flex items-center flex-wrap">
                                    <span className="ml-3 text-xl">{`${name} (${symbol})`}</span>
                                    <div className="ml-3 text-sm text-black/[.48]">
                                        <div className="flex items-center" onClick={() => setStates({...states, toggleSwitch: !states.toggleSwitch})}>
                                            <span className="cursor-pointer">Switch Currency</span>
                                            <img className="w-[18px] h-[18px] ml-2 cursor-pointer" src={`/images/vectors/vector_013.png`} width={0} height={0} sizes="100vw" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ml-4 shrink-0 p-4 rounded-full bg-colGreyA cursor-pointer" onClick={() => props.onEvent({type: 'reset_pop_up'})}>
                                <img className="w-[12px] h-[12px]" src={`/images/vectors/vector_014.png`} width={0} height={0} sizes="100vw" alt=""/>
                            </div>
                            <div className={`${states.toggleSwitch ? 'block' : 'hidden'} absolute left-0 top-[100%] w-full pt-4 px-4`}>
                                <div className="bg-white rounded-xl overflow-hidden border-[1px] border-colGreyB shadow-[-4px_8px_16px_0_rgba(0,0,0,0.08)]">
                                    {
                                        currencies.map((currency: string, b: number) => {
                                            return (
                                                <div key={b} className={`flex items-center px-4 py-6 hover:bg-colBlueB transitionA cursor-pointer ${currency === states.currency ? 'bg-black/[.04]' : ''}`}
                                                    onClick={() => setStates({...states, currency: currency, toggleSwitch: false})}
                                                >
                                                    <img className="w-[35px] h-[35px] object-cover rounded-full" src={`/images/flags/${receipient_details[currency].logo_name}`} width={0} height={0} sizes="100vw" alt=""/>
                                                    <span className="ml-3 text-xl text-black">{`${receipient_details[currency].name} (${receipient_details[currency].symbol})`}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="mb-8 text-black/[.48]">
                            <span>Payable for </span> <span className="capitalize">{mode}:</span>
                            <span className="text-black ml-3 font-medium">{priceMon1} </span><span>{`(one month)`}</span>
                            <span className="text-black ml-3 font-medium">{priceMon3} </span><span>{`(three months)`}</span>
                        </div>
                        <div className="mb-8 bg-colBlueB rounded-xl px-6 py-4">
                            {
                                Object.entries(holder).map(([key, value]: any, a) => {
                                    return (
                                        <div key={a}>
                                            <div className="text-black/[.48] text-sm">
                                                <span>{key}</span>
                                            </div>
                                            <div className="my-2 flex items-center flex-wrap">
                                                <span className="mr-4">{value}</span>
                                                <img onClick={() => copyTextToClipboard('', value)} className="shrink-0 cursor-pointer w-[14px] h-auto" src={`/images/vectors/vector_015.png`} width={0} height={0} sizes="100vw" alt=""/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="font-familyA text-sm flex flex-wrap items-center">
                            <button className="block w-full md:w-[calc(50%-15px)] mb-2 md:mb-0 md:mr-[15px] px-8 py-4 rounded-lg border-[2px] border-transparent bg-black text-white" onClick={() => copyTextToClipboard('all', '')}>
                                Copy details as text
                            </button>
                            <button className="block w-full md:w-[calc(50%-15px)] md:ml-[15px] px-8 py-4 rounded-lg border-[2px] border-black" onClick={() => props.onEvent({type: 'pop_up', value: {index: 2}})}>
                                <img className="inline w-[16px] h-auto mr-2" src={`/images/vectors/vector_016.png`} width={0} height={0} sizes="100vw" alt=""/>
                                <span>Send Proof of Payment</span>
                            </button>
                        </div>
                    </div>
                    <span className={`${states.copied ? '' : 'opacity-0'} fixed left-[50%] bottom-[20px] translate-x-[-50%] bg-black/[.75] text-white text-small rounded-[20px] px-16 py-2 transitionA`}>Copied</span>
                </>
                // :
                // <div className={`${className} absolute mx-auto w-full md:w-[50%] bg-white rounded-xl p-8 py-24 font-bold text-colGreyE left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-center text-lg md:text-2xl`}>
                //     <div className="mx-auto w-[60%] md:w-[60%]">Offer not available in your country!</div>
                // </div>
            }
        </>
    );
}

export default PaymentPopUp001;