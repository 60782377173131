
export const affils = [
    {name: 'affil_001.png', width: '60px', height: '32px'},
    {name: 'affil_002.png', width: '117px', height: '40px'},
    {name: 'affil_003.png', width: '114px', height: '32px'},
    {name: 'affil_004.png', width: '170px', height: '28px'},
    {name: 'affil_005.png', width: '142px', height: '32px'},
    {name: 'affil_006.png', width: '180px', height: '36px'},
    {name: 'affil_007.png', width: '164px', height: '28px'}
];
export const affils_b = [
    {name: 'affil_001.png', width: '60px', height: '32px'},
    {name: 'affil_003.png', width: '114px', height: '32px'},
    {name: 'affil_004.png', width: '170px', height: '28px'},
    {name: 'affil_005.png', width: '142px', height: '32px'},
    {name: 'affil_006.png', width: '180px', height: '36px'},
    {name: 'affil_007.png', width: '164px', height: '28px'}
];