
export const getLoggedUser = () => {
    let id: number| string = '';
    let name: string = '';
    let user_name: string = '';
    if(typeof window !== 'undefined'){
        id = window.localStorage.getItem('id') || '';
        name = window.localStorage.getItem('name') || '';
        user_name = window.localStorage.getItem('user_name') || '';
    }

    return {
        id,
        name,
        user_name
    };
}

export const setLoggedUser = ({id, name, user_name}: typeWorker) => {
    localStorage.setItem('id', `${id}`);
    localStorage.setItem('name', name);
    localStorage.setItem('user_name', user_name);
}

export const removeLoggedUser = () => {
    localStorage && localStorage.removeItem('id');
    localStorage && localStorage.removeItem('name');
    localStorage && localStorage.removeItem('user_name');
}
