import { useEffect, useState } from "react";

interface typeLocal {
    key_mock: string;
    children?: any;
    text?: string;
    type?: string;
    className?: string;
    onEvent?: any;
}

const Response = ({key_mock, text = '', children = <></>, className = '', type = '', onEvent = () => {}}: typeLocal) => {
    // key_mock = 'a';
    const [states, setStates] = useState({
        show: true
    });

    useEffect(() => {
        setStates({
            ...states,
            show: true
        })
        setTimeout(() => {
            setStates({
                ...states,
                show: false
            })
        }, 4000)
    }, [key_mock]);

    // console.log(children);
    // type = 'error';
  
    return (
        <div
            key={key_mock}
            className={`${className}
                fixed z-[1] left-[50%] translate-x-[-50%] w-[fit-content] text-sm sm:text-base md:text-md flex justify-center transitionA
                ${
                    states.show ? 'bottom-4' : 'bottom-[-100%]'
                }
            `}
        >
            <div className={`px-6 py-3 rounded-md shadow-[0px_6px_0_0_rgba(0,0,0,0.2)] transitionA
                    ${
                        type === 'success' ?
                        'bg-colGreenB fill-colGreenA text-colGreenA'
                        :
                        type === 'error' ?
                            'bg-colPinkB fill-[red] text-[red]'
                            :
                            'bg-colGreyE text-white'
                    }`}
            >
                {
                    (children !== '' && children.props?.children) || (children.length) ?
                    children
                    :
                    text !== '' ?
                        <div className={`flex items-center`}>
                            {
                                type !== '' &&
                                <svg className="shrink-0" width="24" height="24" viewBox="0 0 16 16">
                                    {
                                        type === 'success' &&
                                        <>
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                        </>
                                    }
                                    {
                                        type === 'error' &&
                                        <>
                                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                                        </>
                                    }
                                </svg>
                            }
                            <span className={`${type ? 'ml-3' : ''}`}>{text}</span>
                        </div>
                        :
                        ''
                }
            </div>
        </div>
    );
}

export default Response;