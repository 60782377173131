
interface typeLocal {
    className?: string;
    onEvent?: any;
}

const PaymentPopUp002 = ({className = '', onEvent = () => {}}: typeLocal) => {
  
    return (
        <div className={`${className} relative top-[50%] translate-y-[-50%] mx-auto w-full md:w-[50%] bg-white rounded-xl p-8 flex flex-col items-center justify-between text-center`}>
            <img className="w-[250px] h-auto" src="/images/sketches/sketch_027.png" width={0} height={0} sizes="100vw" alt=""/>
            <div className="my-14 font-semibold text-xl">
                <span className="block">Hyper! Your have been set up</span>
                <span className="block">for design greatness.</span>
            </div>
            <div className="text-black/[.64]">
                <span className="block">More information on your onboarding</span>
                <span className="block">will be sent to your email.</span>
            </div>
        </div>
    );
}

export default PaymentPopUp002;