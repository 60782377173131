
export const roundUpMoneyDigit = (n: number, ignore: boolean = false): typeReturnedRoundUpMoneyDigit => {
    let strHuman = '';

    let xyz: any = Math.round(n);
    const len = (xyz.toString()).length;
    
    const nStr = n.toString();

    let x: any = '';
    if(len > 5){
        x = len % 3;
    }

    let baseInt: any = '';
    let baseFloat: any = '';
    // let basePow: any = 0;
    let basePowStr: any = '';

    let basePow: any = Math.floor((len - 1)/ 3);

    let canSubMultiple = false;
    if(x === ''){
        baseInt = n;
    }
    else {
        // basePow = len - x;
        if(x === 0){
            x = 3;
        }

        canSubMultiple = true;
        baseInt = parseFloat(nStr.substring(0, x));
        baseFloat = parseFloat(Math.trunc(n).toString().substring(x, x + 3));
    }

    // console.log(len, x, baseInt);

    baseInt = Math.round(baseInt);
    baseFloat = Math.round(baseFloat);

    if(!ignore){
        if(baseInt >= 20){
            //to nearest tens;
            baseInt = Math.round(baseInt / 10) * 10;
            
            //remove 10;
            baseInt -= 10;
        }
        if(baseFloat >= 20){
            //to nearest tens;
            baseFloat = Math.round(baseFloat / 10) * 10;
            
            //remove 10;
            baseFloat -= 10;
        }
    }

    let baseFloatStr = baseFloat.toString();
    if(baseFloat){
        baseFloatStr = baseFloatStr.replace(/0+$/, '')
    }

    if(canSubMultiple){
        if(basePow === 1){
            basePowStr = 'k';
        }
        else if(basePow === 2){
            basePowStr = 'm';
        }
        else if(basePow === 3){
            basePowStr = 'b';
        }
        else if(basePow >= 4){
            basePowStr = 't';
        }
    }


    // console.log(n, baseInt, baseFloat, basePowStr, basePow);
    strHuman += `${baseInt}` + (baseFloat ? `.${baseFloatStr}` : ``) + basePowStr;

    return {
        to_human: strHuman
    }
}